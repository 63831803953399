<template>
  <vueSeamlessScroll class="content" :data="statusItems" :class-option="classOption">
    <status-item
      v-for="(item, index) in statusItems"
      :item="item"
      :key="index"
    />
  </vueSeamlessScroll>
</template>

<script>
import { getWorkOrderDynamicInfo } from '@/api/bigScreen.js';
import statusItem from './statusItem.vue';
import * as dayjs from 'dayjs';
import vueSeamlessScroll from 'vue-seamless-scroll';

export default {
  name: 'statusRank',
  components: {
    statusItem,
    vueSeamlessScroll,
  },
  computed: {
    classOption () {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data () {
    return {
      statusItems: [],
      lastRefresh: '',
    };
  },
  methods: {
    getInfo () {
      getWorkOrderDynamicInfo({}).then(
        ({ body }) => {
          body = body || [];

          this.statusItems = body.map((v) => ({
            businessCode: v.businessCode,
            content: v.content,
            currentStatus: v.currentStatus,
            customerName: v.customerName,
            operateTime: v.operateTime
              ? dayjs(v.operateTime).format('HH:mm:ss')
              : '--',
          }));
        },
      );
    },
    refreshItems () {
      let currentTimestamp = new Date().getTime();
      if (!this.lastRefresh || (currentTimestamp - this.lastRefresh) > 1000 * 60) {
        this.lastRefresh = currentTimestamp;
        this.getInfo();
      }
      this.timerId = requestAnimationFrame(this.refreshItems);
    },
  },
  mounted () {
    this.refreshItems();
  },
  destroyed () {
    cancelAnimationFrame(this.timerId);
  },
};
</script>
<style scoped lang="scss">
.content {
  box-sizing: border-box;
  width: 100%;
  padding: 0 vw(12);
  height: vh(320);
  overflow: hidden;
}
</style>
