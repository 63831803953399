<template>
  <div class="distribution" :id="distributionData.id"></div>
</template>

<script>
import * as echarts from 'echarts';
import _ from 'lodash';
import randomColor from 'randomcolor';
const designWidth = 1920;
const designHeight = 1080;

export default {
  name: 'distribution',
  props: {
    distributionData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    distributionData () {
      this.drawChart();
    },
  },
  data () {
    return {
      myChart: null,
      baseOpt: {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          type: this.distributionData.id === 'workOrderType' ? 'scroll' : null,
          pageIconSize: '12',
          orient: 'vertical',
          top: 'center',
          left: (window.innerWidth / designWidth) * 160,
          width: (window.innerWidth / designWidth) * 250,
          icon: 'circle',
          align: 'left',
          textStyle: {
            color: '#ffffff',
            rich: this.distributionData.id === 'workOrderType' ? {
              a: {
                color: '#D3E6FF',
                width: 70,
                padding: [0, 12, 0, 0],
              },
              b: {
                fontWeight: 400,
                color: '#FFFFFF',
              },
            } : null,

          },
          formatter: (name) => {
            let retStr = name;
            if (this.distributionData.id === 'workOrderType') {
              let count = this.distributionData.data[name];
              return `{a|${echarts.format.truncateText(name, 70, '14px', '...')}}{b|${count}单}`;
            }
            return retStr;
          },
        },
        series: [
          {
            name: this.distributionData.title,
            type: 'pie',
            color: ['#4D9CF8', '#49EEEB', '#78F68F', '#ECFF67', '#FD9DA3', '#FFB467', '#FD5252', '#7642F9'],
            radius: ['40%', '80%'],
            avoidLabelOverlap: false,
            width: (window.innerWidth / designWidth) * 140,
            height: (window.innerHeight / designHeight) * 140,
            zlevel: 1,
            itemStyle: {
              borderRadius: 2,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            type: 'pie',
            color: ['#4D9CF8', '#49EEEB', '#78F68F', '#ECFF67', '#FD9DA3', '#FFB467', '#FD5252', '#7642F9'],
            radius: ['50%', '100%'],
            width: (window.innerWidth / designWidth) * 140,
            height: (window.innerHeight / designHeight) * 140,
            zlevel: 0,
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              disabled: true,
            },
            data: [
              {
                value: 10,
                itemStyle: {
                  color: '#142e4e',
                },
              },
            ],
          },
          {
            type: 'pie',
            color: ['#4D9CF8', '#49EEEB', '#78F68F', '#ECFF67', '#FD9DA3', '#FFB467', '#FD5252', '#7642F9'],
            radius: ['0', '60%'],
            width: (window.innerWidth / designWidth) * 140,
            height: (window.innerHeight / designHeight) * 140,
            zlevel: 2,
            tooltip: {
              show: false,
            },
            animation: false,
            emphasis: {
              disabled: true,
            },
            label: {
              show: true,
              position: 'center',
              formatter: `{title|${this.distributionData.title}}`,
              rich: {
                title: {
                  color: 'white',
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 10,
                itemStyle: {
                  color: 'rgba(20, 46, 78, 0.5)',
                },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    drawChart () {
      let data = [];
      for (let key in this.distributionData.data) {
        data.push({
          name: key,
          value: parseInt(this.distributionData.data[key]),
        });
      }
      data = _.sortBy(data, i=>-1 * i.value);
      this.baseOpt.color = randomColor({
        luminosity: 'bright',
        format: 'rgb',
        count: 10,
      });
      this.baseOpt.legend.data = data;
      this.baseOpt.series[0].data = data;
      this.myChart.setOption(this.baseOpt);
    },
    generateRandomColors () {
      let colors = [];
      while (colors.length < 6) {
        var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        if (colors.indexOf(randomColor) < 0) {
          colors.push(randomColor);
        }
      }
      return colors;
    },
    disposeChart () {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
    },
    initChart () {
      this.disposeChart();

      this.myChart = echarts.init(document.getElementById(this.distributionData.id));

      this.drawChart();
    },
  },
  mounted () {
    this.initChart();
  },
  beforeDestroy () {
    this.disposeChart();
  },
};
</script>
<style lang="scss" scoped>
.distribution {
  width: 100%;
  height: vh(166);
  padding: vh(12) vw(12);
}
</style>
