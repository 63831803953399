<template>
  <div class="status-item">
    <div class="common">
      <div class="left">
        <span class="code">{{ item.businessCode }}</span
        ><span class="tag" :class="setTagClass(item.currentStatus)">{{
          convertToName(item.currentStatus)
        }}</span>
      </div>
      <div class="right">
        <span class="time">{{ item.operateTime }}</span>
      </div>
    </div>
    <div class="common">
      <div class="left info">{{ item.customerName }}</div>
      <div class="right info">{{ item.content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'status-item',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setTagClass (status) {
      return {
        'to-assign': status === 'TO_BE_ASSIGNED',
        assigned: status === 'ASSIGNED',
        canceled: status === 'CANCELLED',
        received: status === 'ORDER_RECEIVED',
        'in-maintenance': status === 'IN_MAINTENANCE',
        signed: status === 'SIGNED',
        completed: status === 'COMPLETED',
      };
    },
    convertToName (status) {
      switch (status) {
      case 'TO_BE_ASSIGNED':
        return '待指派';
      case 'ASSIGNED':
        return '已指派';
      case 'CANCELLED':
        return '已取消';
      case 'ORDER_RECEIVED':
        return '已接单';
      case 'IN_MAINTENANCE':
        return '维修中';
      case 'SIGNED':
        return '已签字';
      case 'COMPLETED':
        return '已完成';
      default:
        break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$to-assign: rgba(77, 156, 248, 0.4);
$assigned: rgba(73, 238, 235, 0.4);
$canceled: rgba(120, 246, 143, 0.4);
$received: rgba(236, 255, 102, 0.4);
$in-maintenance: rgba(118, 66, 249, 0.4);
$signed: rgba(255, 180, 103, 0.4);
$completed: rgba(253, 82, 82, 0.4);

@mixin common-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.status-item {
  box-sizing: border-box;
  margin: vh(8) 0;
  width: vw(360);
  height: vh(75);
  padding: vh(4) vw(21);

  background: rgba(126, 182, 255, 0.1);
  border-radius: 4px;
  opacity: 0.6;

  .common {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: vh(10) 0;
    height: vh(18);
    font-size: 14px;
    font-weight: 400;
    color: #c8ddff;
    line-height: vh(18);

    .left {
      @include common-overflow;
      .code {
        height: vh(20);
        font-size: 20px;
        color: #ffffff;
        line-height: vh(20);
      }

      .tag {
        vertical-align: top;
        display: inline-block;
        width: vw(48);
        height: vh(18);
        line-height: vh(18);
        font-size: 12px;
        border-radius: 4px;
        margin-left: 1em;
        text-align: center;

        &.to-assign {
          background-color: $to-assign;
        }

        &.assigned {
          background-color: $assigned;
        }

        &.canceled {
          background-color: $canceled;
        }

        &.received {
          background-color: $received;
        }

        &.in-maintenance {
          background-color: $in-maintenance;
        }

        &.signed {
          background-color: $signed;
        }

        &.completed {
          background-color: $completed;
        }
      }

      &.info {
        width: 9em;
      }
    }

    .right {
      @include common-overflow;
      .time {
        color: #ffffff;
      }

      &.info {
        flex: 1;
      }
    }
  }
}
</style>
