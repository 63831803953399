<template>
  <item-wrapper>
    <template slot="title">
      <title-wrapper title="工单趋势"></title-wrapper>
    </template>
    <template slot="content">
      <trend />
    </template>
  </item-wrapper>
</template>

<script>
import itemWrapper from '../itemWrapper.vue';
import titleWrapper from '../titleWrapper.vue';
import trend from './trend.vue';

export default {
  name: 'workOrderTrend',
  components: {
    itemWrapper,
    titleWrapper,
    trend,
  },
};
</script>
