<template>
  <div class="distribution-wrapper">
    <distribution
      v-for="(v, index) in distributionsData"
      :key="index"
      :distributionData="v"
    />
  </div>
</template>

<script>
import distribution from './distribution.vue';
import { getPeriodDistributionInfo } from '@/api/bigScreen.js';
import dayjs from 'dayjs';

const WORK_ORDER_TYPE = 0;
const WORK_ORDER_STATUS = 1;

export default {
  name: 'distributions',
  components: {
    distribution,
  },
  inject: ['provinceNameFn', 'periodFn'],
  computed: {
    provinceName () {
      return this.provinceNameFn();
    },
    period () {
      return this.periodFn();
    },
  },
  watch: {
    provinceName: {
      handler () {
        this.getInfo();
      },
    },
    period: {
      handler () {
        this.getInfo();
      },
    },
  },
  data () {
    return {
      distributionsData: [
        {
          id: 'workOrderType',
          title: '工单类型',
          data: {},
        },
        {
          id: 'workOrderStatus',
          title: '工单状态',
          data: {},
        },
      ],
    };
  },
  methods: {
    async getInfo () {
      let { body } = await getPeriodDistributionInfo({ provinceName: this.provinceName, period: this.period, workOrderStatuses: [], workOrderTypes: []});
      this.distributionsData = [ {
        id: 'workOrderType',
        title: '工单类型',
        data: body[WORK_ORDER_TYPE],
      }, {
        id: 'workOrderStatus',
        title: '工单状态',
        data: body[WORK_ORDER_STATUS],
      }];
    },
    refreshItems () {
      let date = dayjs().valueOf();
      if (!this.lastRefresh || (date - this.lastRefresh) > 60 * 1000) {
        this.lastRefresh = date;
        this.getInfo();
      }
      this.timerId = requestAnimationFrame(this.refreshItems);
    },
  },
  mounted () {
    this.refreshItems();
  },
  destroyed () {
    cancelAnimationFrame(this.timerId);
  },
};
</script>
<style lang="scss" scoped>
.distribution-wrapper {
  margin: vh(15) vw(17);
  width: vw(336);
  height: vh(332);
  background: rgb(5, 25, 49);
  border-radius: 10%;
}
</style>
