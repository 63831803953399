<template>
  <div class="rank-list">
    <table border="0"  cellspacing="0" cellpadding="0">
      <tbody>
        <tr class="theader">
          <th v-for="v in titles" :key="v.name" :width="v.colspan">
            {{ v.name }}
          </th>
        </tr>
        <tr v-for="(item, index) in list" :key="index">
          <td width="10%">{{ index + 1 }}</td>
          <td
            v-for="(k, index) in keys"
            :key="k"
            :width="titles[index].colspan"
            :title="item[k]"
          >
            {{ item[k] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'rank-list',
  props: {
    titles: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.rank-list {
  box-sizing: border-box;
  width: vw(360);
  height: vh(188);
  overflow: hidden;

  table {
    width: vw(360);
    table-layout: fixed;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    border-collapse:collapse;

    .theader {
      background: #3e8aee;
    }

    td {
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
