<template>
  <item-wrapper>
    <template slot="title">
      <title-wrapper title="工单动态"></title-wrapper>
    </template>
    <template slot="content">
      <statusRank />
    </template>
  </item-wrapper>
</template>

<script>
import itemWrapper from '../itemWrapper.vue';
import titleWrapper from '../titleWrapper.vue';
import statusRank from './statusRank.vue';

export default {
  name: 'workOrderStatus',
  components: {
    itemWrapper,
    titleWrapper,
    statusRank,
  },
};
</script>
