<template>
  <div class="content">
    <buttons :status="sortType" @handleClick="handleClick" />
    <rank-list :list="list[sortType]" :keys="keys[sortType]" :titles="titles[sortType]" />
  </div>
</template>

<script>
import Buttons from './buttons.vue';
import RankList from './rankList.vue';
import { getEngineerCycleInfo } from '@/api/bigScreen.js';

export default {
  name: 'engineerRank',
  components: {
    Buttons,
    RankList,
  },
  inject: ['provinceNameFn', 'periodFn'],
  computed: {
    provinceName () {
      return this.provinceNameFn();
    },
    period () {
      return this.periodFn();
    },
  },
  watch: {
    provinceName: {
      handler () {
        this.list['SATISFACTION'] = [];
        this.list['HOW_BUSY'] = [];
        this.list['EFFICIENCY_OF_THE_REPAIR_ORDER'] = [];
        this.getInfo();
      },
    },
    period: {
      handler () {
        this.list['SATISFACTION'] = [];
        this.list['HOW_BUSY'] = [];
        this.list['EFFICIENCY_OF_THE_REPAIR_ORDER'] = [];

        this.getInfo();
      },
    },
  },
  data () {
    return {
      sortType: 'SATISFACTION',
      titles: {
        HOW_BUSY: [
          { name: '排名', colspan: '10%' },
          { name: '姓名', colspan: '20%' },
          { name: '部门', colspan: '35%' },
          { name: '工单总量', colspan: '20%' },
          { name: '未关单', colspan: '15%' },
        ],
        SATISFACTION: [
          { name: '排名', colspan: '10%' },
          { name: '姓名', colspan: '20%' },
          { name: '部门', colspan: '35%' },
          { name: '工单总量', colspan: '20%' },
          { name: '满意度', colspan: '15%' },
        ],
        EFFICIENCY_OF_THE_REPAIR_ORDER: [
          { name: '排名', colspan: '10%' },
          { name: '姓名', colspan: '20%' },
          { name: '部门', colspan: '35%' },
          { name: '工单总量', colspan: '20%' },
          { name: '时长', colspan: '15%' },
        ],
      },
      keys: {
        HOW_BUSY: [
          'principalName',
          'principalDeptName',
          'totalWorkOrderNum',
          'uncloseWorkOrderNum',
        ],
        SATISFACTION: [
          'principalName',
          'principalDeptName',
          'totalWorkOrderNum',
          'avgSatisfaction',
        ],
        EFFICIENCY_OF_THE_REPAIR_ORDER: [
          'principalName',
          'principalDeptName',
          'totalWorkOrderNum',
          'avgWorkingHours',
        ],
      },
      list: {
        SATISFACTION: [],
        HOW_BUSY: [],
        EFFICIENCY_OF_THE_REPAIR_ORDER: [],
      },
    };
  },
  methods: {
    async getInfo () {
      if (this.list[this.sortType].length) {
        return;
      }

      let { body } = await getEngineerCycleInfo({
        provinceName: this.provinceName,
        period: this.period,
        sortType: this.sortType,
      });

      body = body || [];

      this.list[this.sortType] = body.map((v) => {
        const ret = {};

        for (let k of this.keys[this.sortType]) {
          ret[k] = v[k];
        }

        return ret;
      });
    },
    handleClick (v) {
      this.sortType = v;

      this.getInfo();
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  box-sizing: border-box;
  width: 100%;
  padding: 0 vw(17);
}
</style>
