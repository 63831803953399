<template>
  <div class="legend-wrapper">
    <div class="device_box row_box">
      <div class="title">{{isSpecialTenant?'工单统计':'设备统计分析'}}</div>
      <ChartsLegend class="chart_box" v-bind="{...top[0]}" ref="deviceSubordinate" />
      <ChartsLegend class="chart_box" v-bind="{...top[1]}"  ref="deviceBrand" />
    </div>
    <div class="order_box row_box">
      <div class="title">{{isSpecialTenant?'':'工单统计分析'}}</div>
      <ChartsLegend class="chart_box" v-bind="{...bottom[0]}" ref="orderSubordinate" />
      <ChartsLegend class="chart_box" v-bind="{...bottom[1]}" ref="orderBrand" />
    </div>
  </div>
</template>
<script>
import ChartsLegend from './charts.vue';
import { deviceStatistics, customStatistics } from '@/api/bigScreen.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    ChartsLegend,
  },
  data () {
    return {
      top: [{
        chartName: '科室',
        chartData: {},
      }, {
        chartName: '品牌',
        chartData: {},
      }],
      bottom: [{
        chartName: '科室',
        color: '#3CFF60',
        chartData: {},
      }, {
        chartName: '品牌',
        color: '#3CFF60',
        chartData: {},
      }],
    };
  },
  created () {
    this.refreshCharts();
  },
  destroyed () {
    cancelAnimationFrame(this.timerId);
  },
  computed: {
    ...mapGetters(['currentUserInfo']),
    isSpecialTenant () {
      return this.currentUserInfo?.tenantId === '1214572775331729408';
    },
  },
  methods: {
    refreshCharts () {
      let currentTimestamp = new Date().getTime();
      if (!this.lastRefresh || (currentTimestamp - this.lastRefresh) > 1000 * 60) {
        this.lastRefresh = currentTimestamp;
        this.getDeviceStatistics();
      }
      this.timerId = requestAnimationFrame(this.refreshCharts);
    },
    getDeviceStatistics () {
      if (this.isSpecialTenant) {
        customStatistics().then(({ body }) => {
          const { subordinateDeptNUm, positionNum, typeNum, pointNum } = body;
          this.top[0].chartName = '位置';
          this.top[0].chartData = positionNum || {};
          this.top[1].chartName = '科室';
          this.top[1].chartData = subordinateDeptNUm || {};

          this.bottom[0].chartName = '类型';
          this.bottom[0].chartData = typeNum || {};
          this.bottom[1].chartName = '地点';
          this.bottom[1].chartData = pointNum || {};
        });
      } else {
        deviceStatistics().then(({ body }) => {
          if (body['设备']) {
            const { subordinateDeptNUm: deviceSubordinate, brandNum: deviceBrand } = body['设备'];
            this.top[0].chartData = deviceSubordinate;
            this.top[1].chartData = deviceBrand;
          }
          if (body['工单']) {
            const { subordinateDeptNUm: orderSubordinate, brandNum: orderBrand } = body['工单'];
            this.bottom[0].chartData = orderSubordinate;
            this.bottom[1].chartData = orderBrand;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.legend-wrapper {
  box-sizing: border-box;
  width: vw(972);
  height: vh(752);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .row_box {
    display: flex;
    position: relative;
    justify-content: space-between;
    height: vh(360);
    width: 100%;

    .title {
      position: absolute;
      height: 41px;
      line-height: 41px;
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
      padding-left: 21px;
    }

    .chart_box {
      flex: 0 0 48%;
      // background: #eee;
      padding-top: 50px;
      box-sizing: border-box;
    }
  }
}
</style>
