<template>
  <div class="content">
    <item-quantity v-for="item in workOrderItems" :item="item" :key="item.en" />
  </div>
</template>

<script>
import { getWorkorderInfo } from '@/api/bigScreen.js';
import itemQuantity from './itemQuantity.vue';
import dayjs from 'dayjs';

export default {
  name: 'quantities',
  components: {
    itemQuantity,
  },
  inject: ['provinceNameFn'],
  computed: {
    provinceName () {
      return this.provinceNameFn();
    },
  },
  watch: {
    provinceName: {
      handler () {
        this.getInfo();
      },
    },
  },
  data () {
    return {
      workOrderItems: [
        { en: 'day', chn: '本日', num: 0 },
        { en: 'month', chn: '本月', num: 0 },
        { en: 'year', chn: '本年', num: 0 },
        { en: 'tobeassigned', chn: '待指派', num: 0 },
        { en: 'assigned', chn: '待服务', num: 0 },
        { en: 'inmaintenance', chn: '服务中', num: 0 },
      ],
      lastRefresh: '',
    };
  },
  methods: {
    getInfo () {
      getWorkorderInfo({ provinceName: this.provinceName }).then(({ body }) => {
        this.workOrderItems = [
          { en: 'day', chn: '本日', num: body?.dayNum || 0 },
          { en: 'month', chn: '本月', num: body?.monthNum || 0 },
          { en: 'year', chn: '本年', num: body?.yearNum || 0 },
          {
            en: 'tobeassigned',
            chn: '待指派',
            num: body?.toBeAssignedNum || 0,
          },
          { en: 'assigned', chn: '待服务', num: body?.toBeServiceNum || 0 },
          { en: 'inmaintenance', chn: '服务中', num: body?.inServiceNum || 0 },
        ];
      });
    },
    refreshItems () {
      let date = dayjs().valueOf();
      if (!this.lastRefresh || (date - this.lastRefresh) > 60 * 1000) {
        this.lastRefresh = date;
        this.getInfo();
      }
      this.timerId = requestAnimationFrame(this.refreshItems);
    },
  },
  mounted () {
    this.refreshItems();
  },
  destroyed () {
    cancelAnimationFrame(this.timerId);
  },
};
</script>
<style scoped lang="scss">
.content {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 vw(12);
}
</style>
