<template>
  <item-wrapper>
    <template slot="title">
      <title-wrapper :title="`近${period}天时长统计`"></title-wrapper>
    </template>
    <template slot="content">
      <times />
    </template>
  </item-wrapper>
</template>

<script>
import itemWrapper from '../itemWrapper.vue';
import titleWrapper from '../titleWrapper.vue';
import times from './times.vue';

export default {
  name: 'workOrderQuantity',
  components: {
    itemWrapper,
    titleWrapper,
    times,
  },
  inject: ['periodFn'],
  computed: {
    period () {
      return this.periodFn();
    },
  },
};
</script>
