<template>
  <div class="dashboard">
    <dashboard-header :isFullScreen.sync="isFull"></dashboard-header>
    <div class="body">
      <div class="left">
        <work-order-quantity />
        <work-order-status />
        <work-order-trend />
      </div>
      <div class="middle">
        <main-statistics />
        <device-legend />
      </div>
      <div class="right">
        <work-order-cycle-statistics @updatePeriod="updatePeriod" />
        <work-order-cycle-distribution />
        <time-period-statistics />
        <engineerCycleRankinge />
      </div>
    </div>
  </div>
</template>

<script>
import dashboardHeader from './components/dashboardHeader.vue';
import workOrderQuantity from './components/workOrderQuantity';
import workOrderStatus from './components/workOrderStatus';
import workOrderTrend from './components/workOrderTrend';
import workOrderCycleStatistics from './components/workOrderCycleStatistics';
import workOrderCycleDistribution from './components/workOrderCycleDistribution';
import timePeriodStatistics from './components/timePeriodStatistics';
import engineerCycleRankinge from './components/engineerCycleRanking';
import mainStatistics from './components/mainStatistics';
import deviceLegend from './components/deviceLegend';
import { exitScreen } from '@/utils/fullScreen';

export default {
  name: 'dashboard',
  components: {
    dashboardHeader,
    workOrderQuantity,
    workOrderStatus,
    workOrderTrend,
    workOrderCycleStatistics,
    workOrderCycleDistribution,
    timePeriodStatistics,
    engineerCycleRankinge,
    deviceLegend,
    mainStatistics,
  },
  provide () {
    return {
      periodFn: ()=>this.period,
    };
  },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isFull (v) {
      this.$emit('update:isFullScreen', v);
      exitScreen();
    },
  },
  data () {
    return {
      provinceName: '',
      period: '15',
      isFull: this.isFullScreen,
    };
  },
  methods: {
    updatePeriod (payload) {
      this.period = payload;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ * {
  box-sizing: border-box;
}

.dashboard {
    height: 100vh;
    width: 100vw;

    background-image: url('../../assets/imgs/dashboard/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;

    display: flex;
    flex-direction: column;

    .body {
      flex: 1;
      display: flex;
      .left {
        width: vw(460);
      }

      .middle {
        flex: 1;
      }

      .right {
        width: vw(460);
      }
    }
  }
</style>
