<template>
  <div class="item-wrapper">
    <div class="up">
      <div>{{ data.label }}</div>
      <div :class="[data.growthRate >= 0 ? 'rise' : 'decline']">
        {{ (data.growthRate * 100).toFixed(2) + '%' }}
      </div>
    </div>
    <div class="down">
      <span class="quantitiy">{{ data.quantitiy | qianfen }}</span>
      <span class="unit">{{ data.unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dataItem',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    qianfen (a) {
      var re = /(?=(?!\b)(\d{3})+$)/g;
      return (a + '').replace(re, ',');
    },
  },
};
</script>
<style lang="scss" scoped>
.item-wrapper {
  box-sizing: border-box;
  width: vw(176);
  height: vh(80);
  background-image: url('../../../../assets/imgs/dashboard/databg.png');
  background-size: 100% 100%;

  color: #fff;

  .up {
    height: vh(40);
    line-height: vh(40);
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: space-between;
    padding: 0 vw(33) 0 vw(26);

    .rise {
      color: #ff3b30;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        top: vh(10);
        left: 110%;
        width: vw(17);
        height: vh(17);
        background-image: url('../../../../assets/imgs/dashboard/up.png');
        background-size: 100% 100%;
      }
    }

    .decline {
      color: #00e82a;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        top: vh(10);
        left: vw(18);
        width: vw(17);
        height: vh(17);
        background-image: url('../../../../assets/imgs/dashboard/down.png');
        background-size: 100% 100%;
      }
    }
  }

  .down {
    height: vh(34);
    padding: 0 vw(26);

    .quantitiy {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      line-height: 24px;
      letter-spacing: 2px;
    }

    .unit {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
