<template>
  <div class="item-quantity">
    <div class="common" :class="item.en">{{ item.chn }}</div>
    <div class="quantity">{{ item.num | qianfen }}</div>
  </div>
</template>

<script>
export default {
  name: 'item-quantity',
  props: {
    item: {
      type: Object,
      default: () => ({
        en: '',
        chn: '',
        num: 0,
      }),
    },
  },
  filters: {
    qianfen (a) {
      var re = /(?=(?!\b)(\d{3})+$)/g;
      return (a + '').replace(re, ',');
    },
  },
};
</script>
<style lang="scss" scoped>
.item-quantity {
  box-sizing: border-box;
  margin: vh(12) vw(6) vh(12) 0;
  padding-left: vw(6);
  width: vw(116);
  height: vh(80);

  &:last-of-type {
    margin-right: 0;
  }

  .common {
    width: vw(40);
    height: vh(36);
    line-height: vh(36);
    font-size: 16px;
    font-weight: 600;
    color: #d3e6ff;
    text-align: center;
  }

  .quantity {
    box-sizing: border-box;
    padding: 0 vw(12);
    margin-top: vh(7);
    width: vw(116);
    height: vh(36);
    line-height: vh(36);
    background-image: url('../../../../assets/imgs/dashboard/workordernum.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    overflow: hidden;
  }
  .day,
  .month,
  .year {
    background: rgba(62, 138, 238, 0.2);
    border-bottom: vh(2) #3e8aee solid;
  }

  .tobeassigned {
    width: vw(60);
    background: rgba(249, 151, 6, 0.2);
    border-bottom: vh(2) #f99706 solid;
  }

  .assigned {
    width: vw(60);
    background: rgba(77, 156, 248, 0.2);
    border-bottom: vh(2) #4d9cf8 solid;
  }

  .inmaintenance {
    width: vw(60);
    background: rgba(120, 246, 143, 0.2);
    border-bottom: vh(2) #78f68f solid;
  }
}
</style>
