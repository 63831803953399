<template>
  <div class="title-wrapper">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'title-wrapper',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.title-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: vh(38);
  padding-left: vw(18);

  background-image: url('../../../assets/imgs/dashboard/titlebg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
}
</style>
