<template>
  <div class="time-item">
    <div class="logo" :class="item.en"></div>
    <div class="data">
      <div class="title">{{ item.chn }}</div>
      <div class="num">{{ item.num }}小时</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'time-item',
  props: {
    item: {
      type: Object,
      default: () => ({
        en: '',
        chn: '',
        num: 0,
      }),
    },
  },
};
</script>
<style lang="scss" scoped>
.time-item {
  box-sizing: border-box;
  width: vw(115);
  height: vh(52);
  display: flex;
  justify-content: space-between;

  .logo {
    flex: 1;
    height: vh(52);
    border-bottom: vh(4) #3e8aee solid;

    &.averageLengthOfPieOrders {
      background-image: url('../../../../assets/imgs/dashboard/assign.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &.averageCallDuration {
      background-image: url('../../../../assets/imgs/dashboard/receive.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &.averageClosingTime {
      background-image: url('../../../../assets/imgs/dashboard/close.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .data {
    font-size: 12px;
    width: 6em;
    display: flex;
    flex-direction: column;
    border-bottom: 1px rgba(62,138,238,0.4000) solid ;

    .title {
      width: 6em;
      height: vh(26);
      line-height: vh(26);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d3e6ff;
    }

    .num {
      height: vh(26);
      line-height: vh(26);
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #3e8aee;
    }
  }
}
</style>
