<template>
  <div class="map-wrapper">
    <div class="mapShow" id="mapShow"></div>
    <div class="back" v-if="mapName !== 'china'" @click="back">返回</div>
  </div>
</template>

<script>
import { getMapData } from '@/api/bigScreen.js';
import * as echarts from 'echarts';
import { styleUtil } from '../../../../utils/styleUtil';

const designWidth = 1920;
const designHeight = 1080;

const tooltipWidth = 322;
const tooltipHeight = 330;
const tooltipPieHeight = 130;

export default {
  name: 'mapShow',
  data () {
    return {
      maps: {
      },
      mapName: 'china',
      myChart: null,
      geoCoord: {},
      originData: {
        provinceData: {},
        cityData: {},
      },
      engineerOpt: {
        color: ['#ECFF67', '#67FFAC'],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          left: (window.innerWidth / designWidth) * 150,
          width: (window.innerWidth / designWidth) * 160,
          icon: 'circle',
          align: 'left',
          textStyle: {
            color: '#ffffff',
          },
          formatter: (name) => {
            let retStr = name;

            const des = this.engineerOpt.series[0].data.find(
              (v) => v.name === name,
            );

            retStr = `${name}  ${des.value}人`;

            return retStr;
          },
        },
        graphic: {// 环形中间的字
          elements: [
            {
              type: 'text',
              left: (window.innerWidth / designWidth) * tooltipWidth * 0.16,
              top: (window.innerHeight / designHeight) * tooltipPieHeight * 0.45,
              style: {
                text: '0人\n工程师',
                textAlign: 'center',
                fill: '#fff',
                fontSize: 13,
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            radius: ['45%', '65%'],
            avoidLabelOverlap: false,
            width: (window.innerWidth / designWidth) * 140,
            height: (window.innerHeight / designHeight) * 140,
            zlevel: 1,
            itemStyle: {
              borderRadius: 2,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      workOrderOpt: {
        color: [
          '#4D9CF8',
          '#67F3FF',
          '#67FFAC',
          '#ECFF67',
          '#FFB467',
          '#FD5252',
          '#7642F9',
        ],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          left: (window.innerWidth / designWidth) * 150,
          width: (window.innerWidth / designWidth) * 160,
          icon: 'circle',
          align: 'left',
          textStyle: {
            color: '#ffffff',
          },
          formatter: (name) => {
            let retStr = name;

            const des = this.workOrderOpt.series[0].data.find(
              (v) => v.name === name,
            );

            retStr = `${name}  ${des.value}单`;

            return retStr;
          },
        },
        graphic: {// 环形中间的字
          elements: [
            {
              type: 'text',
              left: (window.innerWidth / designWidth) * tooltipWidth * 0.18,
              top: (window.innerHeight / designHeight) * tooltipPieHeight * 0.45,
              style: {
                text: '0单\n工单',
                textAlign: 'center',
                fill: '#fff',
                fontSize: 13,
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            radius: ['45%', '65%'],
            avoidLabelOverlap: false,
            width: (window.innerWidth / designWidth) * 140,
            height: (window.innerHeight / designHeight) * 140,
            zlevel: 1,
            itemStyle: {
              borderRadius: 2,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      mTime: null,
      index: -1,
      chartOpt: null,
      cityMap: {},
      nameMap: {},
      id: '',
      municipality: ['上海', '重庆', '北京', '天津'],
      municipalityId: ['310100', '500100', '110100', '120100'],
      engineerChart: null,
      workOrderChart: null,
      tooltipUpbg: require('@/assets/imgs/dashboard/float-up.png'),
      tooltipDownbg: require('@/assets/imgs/dashboard/float-down.png'),
    };
  },
  methods: {
    convertToName (status) {
      switch (status) {
      case 'IN_MAINTENANCE':
        return '服务中';
      case 'TO_BE_ASSIGNED':
        return '待指派';
      case 'ORDER_RECEIVED':
        return '待服务';
      case 'BUSY':
        return '繁忙中';
      case 'FREE':
        return '空闲中';
      default:
        break;
      }
    },
    async handleMapData () {
      let { body } = await getMapData();

      body = body || [];

      // 处理工单数据
      for (const key in body[0]) {
        const id = body[0][key].adCode;

        this.originData.provinceData[id] = {
          workOrderData: {
            TO_BE_ASSIGNED: body[0][key].quantity['TO_BE_ASSIGNED'] || 0,
            ORDER_RECEIVED: body[0][key].quantity['ORDER_RECEIVED'] || 0,
            IN_MAINTENANCE: body[0][key].quantity['IN_MAINTENANCE'] || 0,
          },
          engineerData: {
            BUSY: 0,
            FREE: 0,
          },
        };

        if (body[0][key].citiesData) {
          this.originData.cityData[id] = {};

          const citiesData = body[0][key].citiesData;

          for (const key in citiesData) {
            let cityId = citiesData[key].adCode;

            if (cityId === '310100') {
              cityId = '310000';
            }

            if (cityId === '500100') {
              cityId = '500000';
            }

            if (cityId === '110100') {
              cityId = '110000';
            }

            if (cityId === '120100') {
              cityId = '120000';
            }

            this.originData.cityData[id][cityId] = {
              workOrderData: {
                TO_BE_ASSIGNED: citiesData[key].quantity['TO_BE_ASSIGNED'] || 0,
                ORDER_RECEIVED: citiesData[key].quantity['ORDER_RECEIVED'] || 0,
                IN_MAINTENANCE: citiesData[key].quantity['IN_MAINTENANCE'] || 0,
              },
              engineerData: {
                BUSY: 0,
                FREE: 0,
              },
            };
          }
        }
      }

      // 处理工程师数据
      for (const key in body[1]) {
        const id = body[1][key].adCode;

        this.originData.provinceData[id] = Object.assign(
          {},
          this.originData.provinceData[id] || {
            workOrderData: {
              TO_BE_ASSIGNED: 0,
              ORDER_RECEIVED: 0,
              IN_MAINTENANCE: 0,
            },
          },
          {
            engineerData: {
              BUSY: body[1][key].quantity['BUSY'] || 0,
              FREE: body[1][key].quantity['FREE'] || 0,
            },
          },
        );

        if (body[1][key].citiesData) {
          if (!this.originData.cityData[id]) {
            this.originData.cityData[id] = {};
          }

          const citiesData = body[1][key].citiesData;

          for (const key in citiesData) {
            let cityId = citiesData[key].adCode;

            if (cityId === '310100') {
              cityId = '310000';
            }

            if (cityId === '500100') {
              cityId = '500000';
            }

            if (cityId === '110100') {
              cityId = '110000';
            }

            if (cityId === '120100') {
              cityId = '120000';
            }

            this.originData.cityData[id][cityId] = Object.assign({}, this.originData.cityData[id][cityId] || {
              workOrderData: {
                TO_BE_ASSIGNED: 0,
                ORDER_RECEIVED: 0,
                IN_MAINTENANCE: 0,
              },
            }, {
              engineerData: {
                BUSY: citiesData[key].quantity['BUSY'] || 0,
                FREE: citiesData[key].quantity['FREE'] || 0,
              },
            });
          }
        }
      }

      this.drawChart();
    },
    registerMaps (name = 'china') {
      if (this.maps[name]) {
        echarts.registerMap(name, this.maps[name]);
        return Promise.resolve();
      }
      let geojsonUrl = '';
      if (name === 'china') {
        geojsonUrl = `${process.env.BASE_URL}geojson/100000.json`;
      } else {
        geojsonUrl = `${process.env.BASE_URL}geojson/${this.cityMap[name]}.json`;
      }
      return fetch(geojsonUrl).then(ret=>ret.json()).then(data=>{
        this.maps[name] = data;
        data.features.forEach((v) => {
          this.geoCoord[v.properties.name] = v.properties.cp;
          this.cityMap[v.properties.name] = v.id;
          this.nameMap[v.id] = v.properties.name;
        });
        if (this.municipality.includes(name)) {
          const specialProvince = this.maps['china'].features.find(t=>t.properties.name === name);
          specialProvince && echarts.registerMap(name, {features: [specialProvince]});
        } else {
          echarts.registerMap(name, this.maps[name]);
        }
      });
    },
    setMapOption (mapName) {
      this.mapName = mapName;

      let seriesData = [];
      let scatterData = [];

      if (mapName === 'china') {
        const keys = Object.keys(this.originData.provinceData);

        for (const k of keys) {
          scatterData.push({
            name: this.nameMap[k],
            value: this.geoCoord[this.nameMap[k]],
          });
        }

        seriesData = this.originData.provinceData;
      } else {
        const id = this.cityMap[mapName];

        if (this.originData.cityData[id]) {
          seriesData = this.originData.cityData[id];

          const keys = Object.keys(seriesData);
          for (const k of keys) {
            let name = this.nameMap[k];
            let value = this.geoCoord[this.nameMap[k]];

            if (k === '310100') {
              name = this.nameMap['310000'];
              value = [
                121.397008085938,
                31.2888137031251,
              ];
            }

            if (k === '500100') {
              name = this.nameMap['500000'];
              value = [
                106.527345,
                29.503843,
              ];
            }

            if (k === '110100') {
              name = this.nameMap['110000'];
              value = [
                116.949932890625,
                40.2602272773438,
              ];
            }

            if (k === '120100') {
              name = this.nameMap['120000'];
              value = [
                117.511217070313,
                39.0288014960938,
              ];
            }

            scatterData.push({
              name,
              value,
            });
          }
        }
      }

      const option = {
        title: {
          text: mapName === 'china' ? '' : mapName,
          subtext: '',
          x: 'center',
          textStyle: {
            color: '#ccc',
          },
        },
        tooltip: {
          show: true,
          formatter: (v) => {
            if (!v.data) {
              return;
            }

            this.id = this.cityMap[v.name];

            this.engineerOpt.series[0].data = [];

            let engineerTotal = 0;

            for (let key in seriesData[this.cityMap[v.name]].engineerData) {
              this.engineerOpt.series[0].data.push({
                name: this.convertToName(key),
                value: seriesData[this.cityMap[v.name]].engineerData[key],
              });

              engineerTotal += seriesData[this.cityMap[v.name]].engineerData[key] * 1;
            }

            this.engineerOpt.graphic.elements[0].style.text = `${engineerTotal}人\n工程师`;

            this.workOrderOpt.series[0].data = [];

            let workOrderTotal = 0;

            for (let key in seriesData[this.cityMap[v.name]].workOrderData) {
              this.workOrderOpt.series[0].data.push({
                name: this.convertToName(key),
                value: seriesData[this.cityMap[v.name]].workOrderData[key],
              });

              workOrderTotal += seriesData[this.cityMap[v.name]].workOrderData[key] * 1;
            }

            this.workOrderOpt.graphic.elements[0].style.text = `${workOrderTotal}单\n工单`;

            this.$nextTick(() => {
              if (this.engineerChart) {
                this.engineerChart.dispose();
                this.engineerChart = null;
              }

              this.engineerChart = echarts.init(
                document.getElementById('engineer'),
              );

              this.engineerChart.setOption(this.engineerOpt);

              if (this.workOrderChart) {
                this.workOrderChart.dispose();
                this.workOrderChart = null;
              }

              this.workOrderChart = echarts.init(
                document.getElementById('workOrder'),
              );
              this.workOrderChart.setOption(this.workOrderOpt);
            }, 10);

            const { px2vw, px2vh } = styleUtil;

            return `<div style="width: ${px2vw(tooltipWidth)}; height: ${px2vh(tooltipHeight)}; background: rgba(0,25,58,0.8000);">
                      <img style="width: 100%;" src="${this.tooltipUpbg}">
                      <div style="text-align: center; font-size: 12px}; line-height: ${px2vh(24)}; vertical-align: middle;">${v.name}</div>
                      <div id="engineer" style="height:${px2vh(tooltipPieHeight)};"></div>
                      <div id="workOrder" style="height:${px2vh(tooltipPieHeight)};"></div>
                      <img style="width: 100%;" src="${this.tooltipDownbg}">
                    </div>`;
          },
        },
        geo: {
          map: mapName,
          roam: false,
          zoom: 1.2,
          label: {
            normal: {
              show: false,
              fontSize: '10',
              color: '#ffffff',
            },
          },
          itemStyle: {
            normal: {
              areaColor: '#0B4695',
              borderColor: '#1062d0',
              borderWidth: 2,
            },
            emphasis: {
              areaColor: '#8dd7fc',
              borderWidth: 1.6,
              shadowBlur: 25,
            },
          },
        },
        series: [
          {
            type: 'map',
            geoIndex: 0,
            data: Object.keys(seriesData).map((id) => ({
              name: this.nameMap[id],
            })),
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              brushType: 'fill',
            },
            itemStyle: {
              normal: {
                color: '#F4E925',
                shadowBlur: 10,
                shadowColor: '#333',
              },
            },
            data: scatterData,
            showEffectOn: 'render',
          },
        ],
      };

      return option;
    },
    drawChart () {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }

      this.myChart = echarts.init(document.getElementById('mapShow'));
      this.chartOpt = this.setMapOption('china');

      this.myChart.off('click');
      this.myChart.on('click', (e) => {
        if (this.mapName === 'china') {
          this.$emit('updateProvince', e.name);
        }

        if (this.maps[e.name]) {
          this.chartOpt = this.setMapOption(e.name);
          this.mapActive();
          this.mouseEvents();
          this.myChart.setOption(this.chartOpt);
        } else {
          this.registerMaps(e.name).then(()=>{
            this.chartOpt = this.setMapOption(e.name);
            this.mapActive();
            this.mouseEvents();
            this.myChart.setOption(this.chartOpt);
          });
        }
      });

      this.mapActive();
      this.mouseEvents();

      this.myChart.setOption(this.chartOpt);
    },
    mouseEvents () {
      // 鼠标划入
      this.myChart.off('mouseover');
      this.myChart.on('mouseover', (e) => {
        // 停止定时器，清除之前的高亮
        clearInterval(this.mTime);
        this.mTime = null;
        this.myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });

      // 鼠标划出重新定时器开始
      this.myChart.off('mouseout');
      this.myChart.on('mouseout', () => {
        this.mapActive();
      });
    },
    mapActive () {
      const dataLength = this.chartOpt.series[0].data.length;

      this.index = -1;

      if (this.mTime) {
        clearInterval(this.mTime);
        this.mTime = null;
      }

      // 用定时器控制高亮
      this.mTime = setInterval(() => {
        // 清除之前的高亮
        this.myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
        });

        this.index = ++this.index % dataLength;

        // 当前下标高亮
        this.myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.index,
        });

        this.myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: this.index,
        });
      }, 5000);
    },
    back () {
      this.$emit('updateProvince', '');

      this.mapName === 'china';

      this.chartOpt = this.setMapOption('china');

      this.mapActive();
      this.mouseEvents();
      this.myChart.setOption(this.chartOpt);
    },
  },
  mounted () {
    this.registerMaps().then(this.handleMapData);
  },
  beforeDestroy () {
    if (this.workOrderChart) {
      this.workOrderChart.dispose();
      this.workOrderChart = null;
    }

    if (this.engineerChart) {
      this.engineerChart.dispose();
      this.engineerChart = null;
    }

    if (this.myChart) {
      this.myChart.off('click');
      this.myChart.off('mouseover');
      this.myChart.off('mouseout');

      this.myChart.dispose();
      this.myChart = null;
    }

    if (this.mTime) {
      clearInterval(this.mTime);
    }
  },
};
</script>
<style lang="scss" scoped>
.map-wrapper {
  box-sizing: border-box;
  width: vw(972);
  height: vh(752);

  position: relative;

  .mapShow {
    width: 100%;
    height: vh(712);
  }

  .back {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background: rgba(62, 138, 238, 0.2);
    height: vh(37);
    line-height: vh(37);
    width: vw(94);
    border-radius: vw(10);
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
