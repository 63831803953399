<template>
  <item-wrapper>
    <template slot="title">
      <title-wrapper title="工单数量（单）"></title-wrapper>
    </template>
    <template slot="content">
      <quantities />
    </template>
  </item-wrapper>
</template>

<script>
import itemWrapper from '../itemWrapper.vue';
import titleWrapper from '../titleWrapper.vue';
import Quantities from './quantities.vue';

export default {
  name: 'workOrderQuantity',
  components: {
    itemWrapper,
    titleWrapper,
    Quantities,
  },
};
</script>
