<template>
  <div class="trend" id="trend"></div>
</template>

<script>
import * as echarts from 'echarts';
import { getWorkOrderTrendInfo } from '@/api/bigScreen.js';
import dayjs from 'dayjs';

// const MAINTAINANCE = 0;
// const REPAIR = 1;

export default {
  name: 'trend',
  inject: ['provinceNameFn'],
  computed: {
    provinceName () {
      return this.provinceNameFn();
    },
  },
  watch: {
    provinceName: {
      handler () {
        this.getInfo();
      },
    },
  },
  data () {
    return {
      baseOpt: {
        legend: {
          show: true,
          right: '0%',
          top: 10,
          icon: 'circle',
          textStyle: {
            color: 'white',
            fontSize: 12,
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: 30,
          },
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          padding: [10, 25, 10, 25],
          axisPointer: {
            type: 'none',
          },
          formatter: function (data) {
            let retStr = '';
            if (data.length) {
              for (let v of data) {
                retStr += `
                  ${v.seriesName} / ${v.name}
                  <br/>
                  工单数：${v.value}
                  <br/>
              `;
              }
            }

            return retStr;
          },
        },
        grid: {
          left: '10%',
          right: '2%',
          top: 60,
          bottom: 40,
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: 'white',
            interval: 0,
            rotate: 30,
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: '#0F89FF',
            },
          },
          data: [],
        },
        yAxis: [
          {
            name: '工单数',
            nameTextStyle: {
              color: 'white',
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: 'white',
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#0F89FF',
              },
            },
          },
        ],
        series: [

        ],
      },
      myChart: null,
      lastRefresh: '',
    };
  },
  methods: {
    async getInfo () {
      let { body } = await getWorkOrderTrendInfo({ provinceName: this.provinceName });
      body = body || { };
      // let colors = ['#2AA5FF', '#3CFF60'];
      let colors = [ '#4D9CF8',
        '#67F3FF',
        '#67FFAC',
        '#ECFF67',
        '#FFB467',
        '#FD5252',
        '#7642F9' ];
      this.baseOpt.series.splice(0, this.baseOpt.series.length);
      Object.keys(body).forEach((key, index) => {
        let data = body[key].reverse();
        if (index === 0) {
          this.baseOpt.xAxis.data = data.map(v => {
            return v.date.substr(v.date.indexOf('-') + 1);
          });
        }
        this.baseOpt.series.push({
          type: 'bar',
          name: key,
          barWidth: '30%',
          itemStyle: {
            barBorderRadius: [3, 3, 0, 0],
            color: colors[index % colors.length],
          },
          data: data.map(t=>t.workOrderNum),
        });
      });

      this.myChart.setOption(this.baseOpt);
    },
    disposeChart () {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
    },
    drawChart () {
      this.disposeChart();

      this.myChart = echarts.init(document.getElementById('trend'));
      this.getInfo();
    },
    refreshItems () {
      let date = dayjs().valueOf();
      if (!this.lastRefresh || (date - this.lastRefresh) > 60 * 1000) {
        this.lastRefresh = date;
        this.drawChart();
      }
      this.timerId = requestAnimationFrame(this.refreshItems);
    },
  },
  mounted () {
    this.refreshItems();
  },
  beforeDestroy () {
    cancelAnimationFrame(this.timerId);
    this.disposeChart();
  },
};
</script>
<style lang="scss" scoped>
.trend {
  width: vw(380);
  height: vh(240);

  box-sizing: border-box;
}
</style>
