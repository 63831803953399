<template>
  <div class="item-wrapper">
    <slot name="title"></slot>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'item-wrapper',
  props: {
    height: {
      type: String,
      default: 'auto',
    },
  },
};
</script>
<style lang="scss" scoped>
.item-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: vh(16) 0  vh(16) vw(40);
}
</style>
