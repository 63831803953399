import server from '../request';

const prefix = '/xiaozi-xmb/wireless/saas/workOrder/board';

// 工单数量
export const getWorkorderInfo = (data) => server.post(`${prefix}/workOrderNumber`, data, {
  stopDefaultLoading: true,
});

// 工单动态
export const getWorkOrderDynamicInfo = (data) => server.post(`${prefix}/workOrderDynamic`, data, {
  stopDefaultLoading: true,
});

// 工单趋势
export const getWorkOrderTrendInfo = (data) => server.post(`${prefix}/workOrderTrend`, data, {
  stopDefaultLoading: true,
});

// 指标
export const getIndicatorInfo = (data) => server.post(`${prefix}/indicator`, data, {
  stopDefaultLoading: true,
});

// 工单周期分布
export const getPeriodDistributionInfo = (data) => server.post(`${prefix}/periodDistribution`, data, {
  stopDefaultLoading: true,
});

// 时长周期统计
export const getTheCycleTimeInfo = (data) => server.post(`${prefix}/theCycleTime`, data, {
  stopDefaultLoading: true,
});

// 工程师周期排名
export const getEngineerCycleInfo = (data) => server.post(`${prefix}/engineerCycle`, data, {
  stopDefaultLoading: true,
});

// 地图数据
export const getMapData = () => server.post(`${prefix}/mapData`, {}, {
  stopDefaultLoading: true,
});

// 医院看板 chart
export const deviceStatistics = () => server.post(`${prefix}/deviceStatistics`, {}, { stopDefaultLoading: true });

// 医院看板 chart
export const customStatistics = () => server.post(`${prefix}/customizedWorkOrderStatistics`, {}, { stopDefaultLoading: true });
