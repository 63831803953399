<template>
  <div class="cycles">
    <div class="common" v-for="item in items" :key="item.label" :class="{selected: currentName === item.en }" @click="handleClick(item)">
      {{ item.label }}
    </div>
  </div>
</template>

<script>

const LAST_HALF_MONTH = 15;
const LAST_ONE_MONTH = 30;
const LAST_TWO_MONTHS = 60;
const LAST_THREE_MONTHS = 90;

export default {
  name: 'cycles',
  data () {
    return {
      items: [
        {
          en: 'last_half_month',
          label: '近15天',
          value: LAST_HALF_MONTH,
        },
        {
          en: 'last_one_month',
          label: '近30天',
          value: LAST_ONE_MONTH,
        },
        {
          en: 'last_two_months',
          label: '近60天',
          value: LAST_TWO_MONTHS,
        },
        {
          en: 'last_three_months',
          label: '近90天',
          value: LAST_THREE_MONTHS,
        },
      ],
      currentName: 'last_half_month',
      lastRefresh: '',
    };
  },
  methods: {
    handleClick (v) {
      this.currentName = v.en;
      this.$emit('updatePeriod', v.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.cycles {
  box-sizing: border-box;
  width: 100%;
  padding-left: vw(17);
  display: flex;
  justify-content: flex-start;
  .common {
    display: flex;
    justify-content: center;
    align-items: center;
    width: vw(69);
    height: vh(32);
    background: rgba(15, 137, 255, 0.3);
    border-radius: 4px;
    border: 1px solid rgba(15, 137, 255, 0.8);
    color: rgba(255, 255, 255, 0.6);
    margin: vh(15) vw(12) vh(15) 0;
    cursor: pointer;

    &.selected {
      background: #0F89FF;
      border-color: #0F89FF;
    }
  }
}
</style>
