<template>
  <item-wrapper>
    <template slot="title">
      <title-wrapper title="工单周期统计"></title-wrapper>
    </template>
    <template slot="content">
      <cycles v-on="$listeners" />
    </template>
  </item-wrapper>
</template>

<script>
import itemWrapper from '../itemWrapper.vue';
import titleWrapper from '../titleWrapper.vue';
import cycles from './cycles.vue';

export default {
  name: 'workOrderTrend',
  components: {
    itemWrapper,
    titleWrapper,
    cycles,
  },
};
</script>
