<template>
  <div class="charts_common">
    <div ref="chartsBox" class="charts_box"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    chartData: {
      type: Object,
      require: true,
    },
    chartName: {
      type: String,
      require: true,
    },
    color: {
      type: String,
      default: '#2AA5FF',
    },
  },
  data () {
    return {
      chartsBox: null,
    };
  },
  computed: {
    newChartData () {
      let newChart = [];
      Object.keys(this.chartData).forEach(i => {
        newChart.push({
          company: i,
          companyVal: this.chartData[i],
        });
      });
      newChart = newChart.sort((a, b) => +b.companyVal - +a.companyVal);
      if (newChart.length > 10) {
        newChart = newChart.slice(0, 10);
      }
      return newChart;
    },
  },
  watch: {
    chartData: function () {
      this.init();
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init () {
      this.disposeChart();
      this.chartsBox = echarts.init(this.$refs.chartsBox);
      const series = [{
        name: this.chartName,
        type: 'bar',
        smooth: true,
        showBackground: true,
        barMaxWidth: 60,
        backgroundStyle: {
          color: 'rgba(136, 132, 132, 0.12)',
        },
        data: this.newChartData.map(i => i.companyVal),
      }];
      this.chartsBox.setOption({
        color: [this.color],
        legend: {
          data: [this.chartName],
          right: 15,
          icon: 'circle',
          align: 'right',
          textStyle: {
            fontSize: '14px',
            color: this.color,
          },
        },
        grid: {
          top: '10%',
          left: '13%',
          right: '3%',
          bottom: '20%',
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          splitLine: false,
          axisLine: {
            lineStyle: {
              color: '#2AA5FF',
              width: 3,
            },
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: '12px',
            lineHeight: 14,
            interval: 0,
            overflow: 'breakAll',
            formatter: function (value) {
              return value.slice(0, 9).replace(/(.{3})/g, '$1\n');
            },
          },
          data: this.newChartData.map(i => i.company),
        },
        yAxis: {
          type: 'value',
          splitLine: false,
          axisLine: {
            lineStyle: {
              color: '#2AA5FF',
              width: 3,
            },
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: '12px',
          },
        },
        series,
      });
    },
    disposeChart () {
      if (this.chartsBox) {
        this.chartsBox.dispose();
        this.chartsBox = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.charts_common {
  width: 100%;
  height: 100%;

  .charts_box {
    width: 100%;
    height: 100%;
  }
}
</style>
