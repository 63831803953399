<template>
  <item-wrapper>
    <template slot="title">
      <title-wrapper :title="`近${period}天工单分布`"></title-wrapper>
    </template>
    <template slot="content">
      <distribution-wrapper />
    </template>
  </item-wrapper>
</template>

<script>
import itemWrapper from '../itemWrapper.vue';
import titleWrapper from '../titleWrapper.vue';
import distributionWrapper from './distributionWrapper.vue';

export default {
  name: 'workOrderTrend',
  components: {
    itemWrapper,
    titleWrapper,
    distributionWrapper,
  },
  inject: ['periodFn'],
  computed: {
    period () {
      return this.periodFn();
    },
  },
};
</script>
