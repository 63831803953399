<template>
  <div class="content">
    <time-item v-for="item in timeItems" :item="item" :key="item.en" />
  </div>
</template>

<script>
import timeItem from './timeItem.vue';
import { getTheCycleTimeInfo } from '@/api/bigScreen.js';
import dayjs from 'dayjs';

export default {
  name: 'times',
  components: {
    timeItem,
  },
  inject: ['periodFn'],
  computed: {
    period () {
      return this.periodFn();
    },
  },
  watch: {
    period: {
      handler () {
        this.getInfo();
      },
      // immediate: true,
    },
  },
  data () {
    return {
      timeItems: [
        { en: 'averageLengthOfPieOrders', chn: '平均派单', num: 0 },
        { en: 'averageCallDuration', chn: '平均接单', num: 0 },
        { en: 'averageClosingTime', chn: '平均关单', num: 0 },
      ],
    };
  },
  methods: {
    async getInfo () {
      let { body } = await getTheCycleTimeInfo({ provinceName: this.provinceName, period: this.period, workOrderStatuses: [], workOrderTypes: [] });

      this.timeItems = [
        { en: 'averageLengthOfPieOrders', chn: '平均派单', num: body.averageLengthOfPieOrders || 0 },
        { en: 'averageCallDuration', chn: '平均接单', num: body.averageCallDuration || 0 },
        { en: 'averageClosingTime', chn: '平均关单', num: body.averageClosingTime || 0 },
      ];
    },
    refreshItems () {
      let date = dayjs().valueOf();
      if (!this.lastRefresh || (date - this.lastRefresh) > 60 * 1000) {
        this.lastRefresh = date;
        this.getInfo();
      }
      this.timerId = requestAnimationFrame(this.refreshItems);
    },
  },
  mounted () {
    this.refreshItems();
  },
  destroyed () {
    cancelAnimationFrame(this.timerId);
  },
};
</script>
<style scoped lang="scss">
.content {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 vw(12);
}
</style>
