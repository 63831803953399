<template>
  <div class="buttons">
    <div class="common" v-for="item in items" :key="item.label" :class="{selected: currentName === item.en }" @click="handleClick(item)">
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'buttons',
  props: {
    status: {
      type: String,
      default: 'satisfaction',
    },
  },
  data () {
    return {
      items: [
        {
          en: 'satisfaction',
          label: '满意度',
          value: 'SATISFACTION',
        },
        {
          en: 'howbusy',
          label: '繁忙程度',
          value: 'HOW_BUSY',
        },
        {
          en: 'efficiency',
          label: '工单效率',
          value: 'EFFICIENCY_OF_THE_REPAIR_ORDER',
        },
      ],
      currentName: 'satisfaction',
      currentIndex: 0,
    };
  },
  methods: {
    handleClick (v) {
      this.currentName = v.en;

      this.currentIndex = this.items.findIndex(i => i.en === v.en);

      this.$emit('handleClick', v.value);
    },
    refreshItems () {
      let currentTimestamp = new Date().getTime();
      if (!this.lastRefresh || (currentTimestamp - this.lastRefresh) > 1000 * 20) {
        this.lastRefresh = currentTimestamp;
        this.currentIndex = (++this.currentIndex) % this.items.length;
        this.handleClick(this.items[this.currentIndex]);
      }
      this.timerId = requestAnimationFrame(this.refreshItems);
    },
  },
  mounted () {
    this.refreshItems();
  },
};
</script>
<style lang="scss" scoped>
.buttons {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .common {
    font-size: 14px;
    text-align: center;
    width: vw(80);
    height: vh(28);
    line-height: vh(28);
    background: rgba(15, 137, 255, 0.3);
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.6);
    margin: vh(15) vw(12) vh(15) 0;
    cursor: pointer;

    &.selected {
      background: #0F89FF;
      border-color: #0F89FF;
    }
  }
}
</style>
